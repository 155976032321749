import React from 'react'
import { graphql } from 'gatsby'

import ProductPage from '~/components/ProductPage'

const IndexPage = ({ data }) => (
  <>
    <ProductPage product={data.shopifyProduct} />
    <footer>
      For subscriptions, email{' '}
      <a href="mailto:hello@saucelah.com" target="_blank" rel="noreferrer">
        hello@saucelah.com
      </a>{' '}
      and we’ll set you up!
    </footer>
  </>
)

export const query = graphql`
  query {
    shopifyProduct(handle: { eq: "batch-4" }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 910) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

export default IndexPage
