import styled from '@emotion/styled'

import { colors } from '../../utils/styles'

export const FormContainer = styled.div`
  font-family: 'Vulf Sans: Medium';
  flex-grow: 1;
  font-size: 1.5rem;
  padding: 2rem;
  border: 3px solid black;
  box-shadow: 5px 5px 0 black;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  label {
    margin-right: 1rem;
  }
`

export const SizeDropdown = styled.div`
  margin-bottom: 1rem;
`

export const ButtonOption = styled.button`
  font-family: 'Vulf Sans';
  font-size: 1.5rem;
  border: 2px solid black;
  box-shadow: 2px 2px 0 black;
  border-radius: 10px;
  padding: 3px 10px;
  padding-top: 0;
  text-align: center;
  appearance: none;
  transition: box-shadow 0.25s linear;
  background: ${props => (props.selected ? `${colors.green}` : 'white')};
  margin-right: 10px;

  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      box-shadow: 0 0 0 black;
      background: ${colors.yellow};
    }
  }
`

export const QuantityInput = styled.div`
  display: flex;
  margin-bottom: 1rem;

  input {
    font-family: 'Vulf Sans';
    font-size: 1.5rem;
    border-bottom: 3px solid black;
    width: 50px;
    font-variant-numeric: tabular-nums;
    text-align: center;
    padding: 0 5px;
  }

  input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`
