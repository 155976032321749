import styled from '@emotion/styled'

import { breakpoints } from '../../utils/styles'

export const Product = styled.div`
  border: 5px solid black;
  padding: 40px;
  border-radius: 40px;
  box-shadow: 15px 15px 0 black;
  background: white;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 15vw;
    margin-top: 10vw;
  }

  @media (max-width: ${breakpoints.m}px) {
    margin-top: 20vw;
    margin-top: 15vw;
  }

  @media (max-width: ${breakpoints.s}px) {
    margin-top: 25vw;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
`

export const ProductTitle = styled.h1`
  font-family: 'Swear Text: Black Cilati', serif;
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 1rem;
`

export const ProductDescription = styled.div`
  font-size: 1.3rem;

  h6 {
    margin-bottom: 0;
  }

  strong {
    font-family: 'Vulf Sans: Bold';
  }
`

export const FlexContainer = styled.div`
  display: flex;
  flex-directon: column;
  flex-flow: column wrap;
  justify-content: normal;
`
