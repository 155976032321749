import React from 'react'

import ProductForm from '~/components/ProductForm'
import {
  Img,
  Container,
  TwoColumnGrid,
  GridLeft,
  GridRight,
} from '~/utils/styles'
import {
  Product,
  ProductTitle,
  ProductDescription,
  FlexContainer,
} from './styles'

const ProductPage = ({ product }) => {
  return (
    <Product>
      {product && (
        <Container>
          <TwoColumnGrid>
            <GridLeft>
              {product.images &&
                product.images.map(image => (
                  <Img
                    fluid={image.localFile.childImageSharp.fluid}
                    key={image.id}
                    alt={product.title}
                  />
                ))}
            </GridLeft>
            <GridRight>
              <FlexContainer>
                <ProductTitle>{product.title}</ProductTitle>
                <ProductDescription
                  dangerouslySetInnerHTML={{
                    __html: product.descriptionHtml,
                  }}
                />
                <ProductForm product={product} />
              </FlexContainer>
            </GridRight>
          </TwoColumnGrid>
        </Container>
      )}
    </Product>
  )
}

export default ProductPage
